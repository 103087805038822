.zh-btn {

  &.zh-btn-blue {

    color: $main-color;
    border: 1px solid $main-color;
    border-bottom: 3px solid $main-color;
    &:hover {
      border-color: $main-color;
      background-color: $main-color;
      color: #ffffff;
    }
  }

  border: 1px solid #FFFFFF;
  border-radius: 25px;
  color: #ffffff;
  padding: 10px 25px;
  transition: all 0.1s linear;
  border-bottom: 3px solid #ffffff;
  box-sizing: border-box;
  &:hover {
    border-color: $main-color;
    background-color: #fff;
    color: $main-color;
  }


}

.zh__video_wrapper {
  position: relative;

  .zh__video {
    width: 100%;
    height: auto;
    box-shadow: 0px 14px 40px -5px rgba(0,0,0,0.2);
  }

  .zh__video_content {
    position: absolute;
    display: inline-flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    z-index: 999;
    align-items: center;
    justify-content: center;


    .zh__video_play {
      width: 100px;
      height: 100px;
      transition: all 0.5s ease-in;
      background: url(../images/play.svg) no-repeat center / contain;
      cursor: pointer;
      &:hover {
        background-image: url(../images/play-blue.svg);
      }
    }
  }

}


section {
  padding: 70px 0 100px 0;

  .container {

  }

  .section__title {
    font-weight: bold;
    font-size: 46px;
    text-align: center;
    margin-bottom: 25px;
  }

  .section__desc {
    font-size: 24px;
    text-align: center;
    margin-bottom: 25px;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

.form_input {
  height: 50px;
  width: 100%;
  background: #fff;
  padding: 0 30px;
  font-size: 16px;
  outline: none;
  box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.2);
  border-radius: 4px;
  border: 2px solid #ffffff;
  &:focus {
    border: 2px solid $main-color;
  }
}

.callback-form {
  .form-field {
    margin-bottom: 15px;
    .form-label {
      color: $main-color;
      font-weight: 500;
      .required {
        color: #ae0000;
      }
    }
  }

  .btn {
    width: 100%;
    margin: 5px 0;
  }
  
  .agreements {
    text-align: center;
    font-size: 14px;
    color: #626262;
  }


}

#form-result {
  color: $main-color;
  font-size: 22px;
}
