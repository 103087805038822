section.catalog {
  &.blue {
    background-color: $main-color;
    color: #ffffff;
  }

  .catalog_container {
    @include make-container();
    max-width: 940px;
  }

  .catalog__items {
    display: flex;
    @include make-row();
    justify-content: center;

    .catalog__item {

      &:first-child {
        @include make-col(12);
        .catalog__img img {
          max-width: 600px;
          height: auto;
        }
      }


      @include make-col(4);
      @include media-breakpoint-down(sm) {
        @include make-col(12);
      }

      .catalog__img img {
        width: 100%;
        height: auto;
      }

      .catalog__title {

        height: 50px;
        background: #053598;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        color: #ffff;

      }


      a {
        display: block;
        //min-height: 235px;
        margin: 20px;
        text-align: center;
        background-color: #fff;
        box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
        transition: all .1s ease-in-out;
        &:hover {
          text-decoration: none;
          transform: scale(1.1);
          border: 3px solid #ffffff;
        }
      }
    }
  }

}