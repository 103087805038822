@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Italic.eot');
  src: local('Cera Pro Italic'), local('CeraPro-Italic'),
  url('../fonts/CeraPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-Italic.woff') format('woff'),
  url('../fonts/CeraPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Regular.eot');
  src: local('Cera Pro Regular'), local('CeraPro-Regular'),
  url('../fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-Regular.woff') format('woff'),
  url('../fonts/CeraPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-BlackItalic.eot');
  src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic'),
  url('../fonts/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-BlackItalic.woff') format('woff'),
  url('../fonts/CeraPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Light.eot');
  src: local('Cera Pro Light'), local('CeraPro-Light'),
  url('../fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-Light.woff') format('woff'),
  url('../fonts/CeraPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-BoldItalic.eot');
  src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic'),
  url('../fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-BoldItalic.woff') format('woff'),
  url('../fonts/CeraPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Black.eot');
  src: local('Cera Pro Black'), local('CeraPro-Black'),
  url('../fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-Black.woff') format('woff'),
  url('../fonts/CeraPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Medium.eot');
  src: local('Cera Pro Medium'), local('CeraPro-Medium'),
  url('../fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-Medium.woff') format('woff'),
  url('../fonts/CeraPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Bold.eot');
  src: local('Cera Pro Bold'), local('CeraPro-Bold'),
  url('../fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-Bold.woff') format('woff'),
  url('../fonts/CeraPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-Thin.eot');
  src: local('Cera Pro Thin'), local('CeraPro-Thin'),
  url('../fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-Thin.woff') format('woff'),
  url('../fonts/CeraPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-MediumItalic.eot');
  src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic'),
  url('../fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-MediumItalic.woff') format('woff'),
  url('../fonts/CeraPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-LightItalic.eot');
  src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic'),
  url('../fonts/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-LightItalic.woff') format('woff'),
  url('../fonts/CeraPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/CeraPro-ThinItalic.eot');
  src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic'),
  url('../fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CeraPro-ThinItalic.woff') format('woff'),
  url('../fonts/CeraPro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

