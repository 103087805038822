section.about {


  background-color: #fafafa;

  .about_desc {
    font-size: 24px;
  }

  .certs_wrapper {
    border-bottom: 3px solid $main-color;
    margin-top: 3em;
    position: relative;

    .certs {
      //  max-height: 200px;
      // overflow: hidden;
      .cert {
        height: 200px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;

      }
    }

    .blk {
      position: absolute;
      width: 100%;
      height: 200px;
      background-color: red;
    }
  }
}

section.advantages {
  padding-top: 0 !important;
  padding-bottom: 50px !important;
  .advantage__item {
    background-color: $main-color;
    color: #ffffff;
    padding: 15px;
    min-height: 260px;
    .advantage__title {
      font-size: 20px;
      font-weight: 500;
      margin: 15px 0;
    }
  }
}