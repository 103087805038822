@mixin caret-down() {
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.64645 4.35355C3.84171 4.54882 4.15829 4.54882 4.35355 4.35355L7.53553 1.17157C7.7308 0.976312 7.7308 0.659729 7.53553 0.464467C7.34027 0.269205 7.02369 0.269205 6.82843 0.464467L4 3.29289L1.17157 0.464465C0.976312 0.269203 0.659729 0.269203 0.464467 0.464465C0.269205 0.659727 0.269205 0.97631 0.464467 1.17157L3.64645 4.35355ZM3.5 3L3.5 4L4.5 4L4.5 3L3.5 3Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin caret-up() {
  border-top: 0;
  border-right: $caret-width solid transparent;
  border-bottom: $caret-width solid;
  border-left: $caret-width solid transparent;
}

@mixin caret-right() {
  border-top: $caret-width solid transparent;
  border-right: 0;
  border-bottom: $caret-width solid transparent;
  border-left: $caret-width solid;
}

@mixin caret-left() {
  border-top: $caret-width solid transparent;
  border-right: $caret-width solid;
  border-bottom: $caret-width solid transparent;
}

@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
      content: "";
      @if $direction == down {
        @include caret-down();
      } @else if $direction == up {
        @include caret-up();
      } @else if $direction == right {
        @include caret-right();
      }
    }

    @if $direction == left {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        margin-right: $caret-spacing;
        vertical-align: $caret-vertical-align;
        content: "";
        @include caret-left();
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
