.page__common {
  .page__common_wrapper {
    background: #FFFFFF;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    min-width: 300px;
  }
  .parent__pages {
    a {
      display: block;
      padding: 10px 15px;
      &.active {
        color: #ffffff;
        background-color: $main-color;
      }
    }
  }
  .page__image {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    width: 100%;
    height: auto;
  }

  p {
    font-size: 18px !important;
  }

  ul li {
    font-size: 18px !important;
  }
}