header {
  background-color: $main-color;

  .logo {
    display: inline-flex;
    width: 134px;
    height: 45px;
    margin-right: 100px;
    //background-size: contain;
    //background: url('../images/logo.svg') no-repeat center;
    img {
      width: 100%;
      height: 45px;
    }

    @include media-breakpoint-down(lg) {
      margin-right: 0;
      width: 110px;
    }
  }

  .navbar-toggler {
    color: #ffffff;
    font-size: 14px;
    padding: 0 5px;
  }

  ul.navigation {
    padding: 0;
    margin: 0;
    display: flex;

    li.navigation__item {
      display: inline-flex;
      position: relative;
      padding: 0 15px;

      &.active {
        span {
          border-bottom: 3px solid $secondary-color;
        }
      }

      & > a {
        padding: 28px 0;
        text-decoration: none;
      }

      a {
        &.parent {
          position: relative;

          &:after {
            content: " ";
            position: absolute;
            top: 50%;
            right: -12px;
            width: 5px;
            height: 5px;
            background: transparent;
            margin-top: -3px;
            opacity: .5;
            transform: rotate(45deg);
            border-bottom: 1px solid #ffffff;
            border-right: 1px solid #ffffff;
          }
        }

        display: block;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;

        span {
          color: #ffffff;
        }
      }

      &:hover {
        & > ul {
          opacity: 1;
          transform: translateY(0px);
          left: 0;
          z-index: 6666;
        }
      }

      & > ul {
        list-style: none;
        display: block;
        position: absolute;
        top: 70px;
        left: -9999px;
        opacity: 0;
        transform: translateY(10px);
        min-width: 400px;

        padding: 30px 30px 15px 35px;
        background: #fff;
        box-shadow: 0px 14px 52px 6px rgba(0, 0, 0, 0.2);
        transition: opacity .3s ease, transform .3s ease;

        li {
          margin-bottom: 15px;
          font-size: 16px;

          a {
            font-size: 14px;
          }
        }

        &:after {
          content: " ";
          position: absolute;
          width: 10px;
          height: 10px;
          background: #fff;
          top: -5px;
          left: 34px;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    @include media-breakpoint-down(md) {
      li.navigation__item {

        & > a {
          font-size: 10px;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      display: block !important;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 3px solid #ffffff;
      li.navigation__item {
        display: block !important;
        padding: 0;

        & > a {
          padding: 28px 0 0 0;
          font-size: 14px;

          &:after {
            display: none;
          }
        }

        & > ul {

          position: relative;
          top: 0;
          left: 0;
          opacity: 1;
          min-width: auto;
          padding: 15px 20px;

          & li:last-child {
            margin: 0;
          }

          &:after {
            display: none;
          }


        }
      }
    }

  }

  .callback, .header-contact__block {
    order: 1;
  }

  .header-contact__block {
    text-align: right;
    margin-right: 30px;


    .header-contact__phone {
      line-height: 18px;

      a {
        font-weight: 700;
        color: #ffffff;
        font-size: 18px;
        text-decoration: none;
      }
    }

    .header-contact__email {
      line-height: 16px;

      a {
        font-weight: 300;
        color: #ffffff;
        font-size: 16px;
        text-decoration: none;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      order: 0;
      .header-contact__phone a, .header-contact__email a {
        font-size: 14px;

      }

    }
  }

}