.footer {
  margin-top: 100px;
  background-color: #fff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  color: $main-color;
  font-size: 20px;

  .footer__logo {
    width: 135px;
    height: 45px;
  }

  .footer__col {


    .footer__col_title {
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 15px;
    }

    .footer__col_list {

      list-style: none;
      padding: 0;

      margin: 0;

      li {
        margin-bottom: 5px;
      }
    }
  }
}