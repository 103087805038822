section.intro {
  position: relative;
  width: 100%;
  height: calc(100vh - 88px);
  background: rgba(0, 0, 0, 0.5);

  @media (max-height: 750px) {
    height: auto;
  }

  @include media-breakpoint-down(md) {
    height: auto;
  }

  .intro_bg__video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;

    h1 {
      font-weight: 900;
      font-size: 46px;
      line-height: 50px;
      margin-bottom: 35px;

      @include media-breakpoint-down(sm) {
        text-align: center;
        font-size: 36px;
        line-height: 100%;
      }

      @include media-breakpoint-down(lg) {
        font-size: 36px;
      }
    }

    p {
      font-size: 24px;
      line-height: 135%;

      @include media-breakpoint-down(sm) {
        text-align: center;
        font-size: 20px;
        line-height: 100%;
      }
    }



    color: #f1f1f1;
    width: 100%;
    height: 100%;

    .advantages__block {

      @include media-breakpoint-down(md) {
        display: none;
      }

      border: 1px solid rgba(255, 255, 255, 0.5);

      .advantage__item {
        display: flex;
        padding: 30px 15px;
        justify-items: center;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        @include media-breakpoint-down(lg) {
          padding: 5px;
        }

        .advantage__img {
          margin-right: 20px;
        }

        .advantage__title {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 5px;
        }

        .advantage__desc {
          font-size: 16px;
        }
      }
    }

    .catalog__info {


      @include media-breakpoint-down(sm) {
        display: none;
      }

      height: 80px;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      background-color: $main-color;

      .catalog__info_arrow {
        width: 80px;
        height: 100%;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
        @include arrow;

      }

      .catalog__info_title {
        padding: 0 80px;

        font-size: 24px;
        font-weight: 500;
        vertical-align: middle;
      }
    }
  }
}