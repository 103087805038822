// Core variables and mixins
@import 'theme/variables';
@import 'theme/mixins';
@import 'bootstrap/bootstrap';
@import 'bootstrap/variables';


@import 'theme/fonts';
@import 'theme/typography';
@import 'theme/forms';
@import 'theme/breadcrumbs';


@import 'theme/header';
@import 'theme/footer';
@import 'theme/menu';


@import 'theme/section/intro';
@import 'theme/section/catalog';
@import 'theme/section/about';

@import 'theme/pages/common';
@import 'theme/pages/contact';